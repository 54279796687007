import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

const token = localStorage.getItem("Admintoken")
const apiURL = process.env.REACT_APP_BACKEND_URL

class ChartApex extends Component {
    constructor(props) {
        super(props)

        this.state = {
            options: {
                colors: ["#ccc", "#3c4ccf", "#02a499"],
                chart: {
                    zoom: { enabled: false },
                    toolbar: { show: false },
                },
                legend: { show: false },
                dataLabels: { enabled: false },
                stroke: { curve: "smooth", width: 2 },
                grid: {
                    borderColor: "#f8f8fa",
                    row: {
                        colors: ["transparent", "transparent"],
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: [], // Start with an empty array
                    axisBorder: { show: false },
                    axisTicks: { show: false },
                },
            },
            series: [
                { name: "Activated", data: [] },
                { name: "Pending", data: [] },
                { name: "Deactivated", data: [] },
            ],
        }
    }

    componentDidMount() {
        this.fetchChartData()
    }

    fetchChartData = async () => {
        try {
            const response = await fetch(
                `${apiURL}/api/admin/dashboard/company/charts`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )

            if (!response.ok) {
                throw new Error("Network response was not ok")
            }

            const data = await response.json()
            console.log("Fetched chart data:", data) // Log the data for debugging

            // Set the state with the fetched data
            this.setState({
                series: data.data, // Ensure this matches the structure of your API response
                options: {
                    ...this.state.options,
                    xaxis: {
                        categories: Array.from(
                            { length: data.data[0].data.length },
                            (_, i) => `${i + 1}`
                        ), // Adjust based on your actual data
                    },
                },
            })
        } catch (error) {
            console.error("Error fetching chart data:", error)
        }
    }

    render() {
        return (
            <React.Fragment>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height="290"
                />
            </React.Fragment>
        )
    }
}

export default ChartApex
