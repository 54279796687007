import {
    USER_COUNT,
    USER_COUNT_API_ERROR,
} from "./actionTypes"

export const userCount = (count) => {
    console.log("user count details here.", count);
    return {
        type: USER_COUNT,
        payload: count,
    }
}

export const userApiError = error => {
    return {
        type: USER_COUNT_API_ERROR,
        payload: error,
    }
}