import { call, put, takeEvery } from "redux-saga/effects"
import { USER_COUNT } from "./actionTypes"
import { userApiError, userCount } from "./actions"

function* UserCount({ payload }) {
    try {
    } catch (error) {
        yield put(userApiError(error.message)) // Pass the error message
        // Handle navigation if needed
        if (error.message === "Token verification failed") {
            // Navigate to login here
            history.push("/login") // Assuming history is passed correctly
        }
    }
}

function* userSaga() {
    yield takeEvery(USER_COUNT, UserCount)
}

export default userSaga
