import { useEffect, useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { Card, Col, Row } from "reactstrap"
const { useParams, useNavigate } = require("react-router-dom")

const ServiceDetails = () => {
  const { visaID } = useParams()
  const [visaData, setVisaData] = useState([])
  const [loading, setLoading] = useState(true) // New loading state
  const token = localStorage.getItem("Admintoken")
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_BACKEND_URL;

  document.title = "Service Details | Admin & Dashboard"

  const fetchVisaDetails = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${apiURL}/api/admin/${visaID}`, {
        method: "GET", // GET is the default method, but it's good practice to specify it explicitly
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      const res = await response.json()
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        console.log("res premium visa details", res.data)
        setVisaData(res.data)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false) // Set loading to false after fetching
    }
  }

  useEffect(() => {
    fetchVisaDetails()
  }, [])

  const download = e => {
    e.preventDefault()

    const url = e.target.href
    console.log(url)

    fetch(url, {
      method: "GET",
      headers: {
        // You can add custom headers here if needed
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.arrayBuffer()
      })
      .then(buffer => {
        const blob = new Blob([buffer])
        const link = document.createElement("a")
        const fileName = url.split("/").pop() // Extract file name from URL
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName // Use the extracted file name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // Clean up
        window.URL.revokeObjectURL(link.href) // Release the object URL
      })
      .catch(err => {
        console.error("Download failed:", err)
      })
  }

  console.log("visa Data", visaData)

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col lg={12}>
              <Card style={{ padding: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "600" }}>
                  {visaData?.serviceName} Service
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                    {" "}
                    <strong> Jurisdiction : </strong>{" "}
                    <span style={{ textAlign: "end" }}>
                      {visaData?.jurisdiction}{" "}
                    </span>
                  </Col>
                  <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                    {" "}
                    <strong> No Of Applicants : </strong>{" "}
                    {visaData?.noOfApplicants}
                  </Col>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                    <strong>Application Status :</strong> {visaData?.status}
                  </Col>
                  <Col lg={6} style={{ fontSize: "18px", marginTop: "10px" }}>
                    <strong> Applied Date : </strong>
                    {visaData?.createdAt?.substring(0, 10)}
                  </Col>
                </div>
              </Card>
              <div style={{ marginTop: "10px" }}>
                <Card>
                  <div style={{ padding: "20px" }}>
                    <p style={{ fontSize: "20px", fontWeight: "600" }}>
                      Applicants Details
                    </p>

                    {loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#6f42c1"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <Row>
                        {visaData && visaData?.applicants?.length > 0 ? (
                          visaData?.applicants?.map((el, i) => (
                            <>
                              <h4>Applicant {i + 1}</h4>
                              <Col lg={12} style={{ marginTop: "10px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Col lg={6}>
                                    <p>
                                      <strong>FullName</strong> :
                                      <span>{el?.name} </span>
                                    </p>
                                    <p>
                                      <strong>Country Of Birth</strong> :{" "}
                                      {el?.countryOfBirth}
                                    </p>
                                    <p>
                                      <strong>Nationality</strong> :{" "}
                                      {el?.nationality}
                                    </p>
                                    <p>
                                      <strong>DOB</strong> :{" "}
                                      {el?.dob?.substring(0, 10)}
                                    </p>
                                  </Col>
                                  <Col lg={6}>
                                    <p>
                                      <strong>Gender</strong> : {el?.gender}
                                    </p>
                                    <p>
                                      <strong>Marital Status</strong> :
                                      {el?.maritalStatus}
                                    </p>
                                    <p>
                                      <strong>Relation</strong> : {el?.relation}
                                    </p>
                                    <p>
                                      <strong>Country</strong>: {el?.country}
                                    </p>
                                    <p>
                                      <strong>Current Profession</strong> :{" "}
                                      {el?.currentProfession}
                                    </p>
                                  </Col>
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <h4>Contact Details</h4>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Col lg={6}>
                                      <p>
                                        <strong>Email</strong> :{" "}
                                        {el?.contactDetails?.email}
                                      </p>
                                      <p>
                                        <strong>Mobile No</strong> :{" "}
                                        {el?.contactDetails?.mobileNo}
                                      </p>
                                    </Col>
                                    <Col lg={6}>
                                      <p>
                                        <strong> TelePhone No</strong> :{" "}
                                        {el?.contactDetails?.phoneNumber}
                                      </p>
                                      <p>
                                        {" "}
                                        <strong>Address</strong> :{" "}
                                        {el?.contactDetails?.address}
                                      </p>
                                    </Col>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Col lg={6} style={{ marginTop: "5px" }}>
                                    <h4>Health Details</h4>
                                    <p>
                                      <strong> Any Infection</strong> :{" "}
                                      {el?.healthDetails?.infection
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </Col>
                                  <Col lg={6} style={{ marginTop: "5px" }}>
                                    <h4>Income Details</h4>
                                    <p>
                                      <strong> Income Currency</strong> :{" "}
                                      {el?.incomeRevenue?.currency}
                                    </p>
                                  </Col>
                                </div>
                                <div
                                  style={{
                                    marginTop: "5px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    justifyItems: "start",
                                  }}
                                >
                                  <Col lg={6}>
                                    <h4 style={{ marginTop: "5px" }}>
                                      Passport Details
                                    </h4>
                                    <p>
                                      <strong>Application Type</strong> :{" "}
                                      {el?.renewal?.applicationType}
                                    </p>
                                    {el?.renewal?.premiumVisaNo && (
                                      <p>
                                        <strong> Premium Visa No</strong> :{" "}
                                        {el?.renewal?.premiumVisaNo}
                                      </p>
                                    )}
                                    {el?.renewal?.validateDate && (
                                      <p>
                                        <strong> Validate Date</strong> :{" "}
                                        {el?.renewal?.validateDate?.substring(
                                          0,
                                          10
                                        )}
                                      </p>
                                    )}
                                  </Col>
                                  <Col lg={6}>
                                    <h4>Security Details</h4>
                                    <p>
                                      <strong> Any Criminal cases?</strong> :{" "}
                                      {el?.securityDetails?.criminalCases
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </Col>
                                </div>
                                <div>
                                  <h4>Stay Details</h4>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Col lg={6} style={{ marginTop: "5px" }}>
                                      <p>
                                        <strong> Arrival Date</strong> :{" "}
                                        {el?.stayDetails?.arrivalDate?.substring(
                                          0,
                                          10
                                        )}
                                      </p>
                                      <p>
                                        <strong> Departure Date</strong> :{" "}
                                        {el?.stayDetails?.departureDate?.substring(
                                          0,
                                          10
                                        )}
                                      </p>
                                    </Col>
                                    <Col lg={6} style={{ marginTop: "5px" }}>
                                      <p>
                                        {" "}
                                        <strong> Stay Type</strong> :{" "}
                                        {el?.stayDetails?.type}
                                      </p>
                                      <p>
                                        <strong> Stay Descriptions</strong> :{" "}
                                        {el?.stayDetails?.workTypeDescription}
                                      </p>
                                    </Col>
                                  </div>
                                </div>
                                <div>
                                  <h4>Documents</h4>
                                  <Col lg={6}>
                                    <div>
                                      {el?.documents?.passport && (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {" "}
                                          <Col lg={4}>
                                            {" "}
                                            <p>
                                              <strong> Passport</strong>:{" "}
                                            </p>{" "}
                                          </Col>
                                          <Col lg={4}>
                                            {" "}
                                            <a
                                              href={el?.documents?.passport}
                                              download
                                              onClick={e => download(e)}
                                            >
                                              Download
                                            </a>{" "}
                                          </Col>
                                          <Col lg={4}>
                                            {" "}
                                            <a
                                              href={el?.documents?.passport}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              View Document
                                            </a>
                                          </Col>
                                        </div>
                                      )}
                                      {el?.documents?.photo && (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {" "}
                                          <Col lg={4}>
                                            {" "}
                                            <p>
                                              {" "}
                                              <strong> Photo</strong> :{" "}
                                            </p>{" "}
                                          </Col>
                                          <Col lg={4}>
                                            {" "}
                                            <a
                                              href={el?.documents?.photo}
                                              download
                                              onClick={e => download(e)}
                                            >
                                              Download
                                            </a>{" "}
                                          </Col>
                                          <Col lg={4}>
                                            {" "}
                                            <a
                                              href={el.documents?.photo}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              View Document
                                            </a>
                                          </Col>
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </>
                          ))
                        ) : (
                          <div>
                            <p
                              colSpan="6"
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              Applicant Details Not Available.
                            </p>
                          </div>
                        )}
                      </Row>
                    )}
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default ServiceDetails
