import { USER_COUNT, USER_COUNT_API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  count: 0,
}

const usersCount = (state = initialState, action) => {
  switch (action.type) {
    case USER_COUNT:
      return {
        ...state,
        count: action.payload,
      }
    case USER_COUNT_API_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export default usersCount
