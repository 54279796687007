import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class DonutChart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {
        colors: ["#000000", "#3c4ccf", "#02a499"],
        labels: ["Premium Visa", "GOAML", "Incorporate Domestic Company"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: this.props?.series || [],
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.series !== this.props?.series) {
      this.setState({ series: this.props?.series })
    }
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height="285"
        />
      </React.Fragment>
    )
  }
}

export default DonutChart
