import { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, Col, Row, Table } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { ThreeDots } from "react-loader-spinner"
import "../Dashboard/services/datatables.scss"
import { MDBDataTable } from "mdbreact"
const Users = () => {
  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const token = localStorage.getItem("Admintoken")
  const apiURL = process.env.REACT_APP_BACKEND_URL
  document.title = "Users | Admin & Dashboard"

  const fetchUserslist = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${apiURL}/api/admin/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      const res = await response.json()
      if (res) {
        if (res.message == "Token verification failed") {
          navigate("/login")
        }
        setUserList(res.data)
        console.log("res users list...............", res)
      }
    } catch (error) {
      console.log("error in fetchUsers", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUserslist()
  }, [])

  const handleServices = id => {
    return navigate(`/service/${id}`)
  }

  const rows = userList?.map((user, i) => ({
    ...user,
    ID: i + 1,
    fullName: user?.fullName, // Keep this for sorting
    displayFullName: (
      <p
        style={{ cursor: "pointer", color: "blue", margin: 0 }}
        onClick={() => handleServices(user?._id)}
      >
        {user?.fullName}
      </p>
    ),
    Country: "Mauritius",
    createdAt: user.createdAt?.substring(0, 10),
    mobile: "6345354526",
  }))

  //datatables here.
  const data = {
    columns: [
      {
        label: "#ID",
        field: "ID",
        sort: "asc",
        width: 100,
      },
      {
        label: "Full Name",
        field: "fullName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Country",
        field: "Country",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile No",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      {
        label: "Create Date",
        field: "createdAt",
        sort: "asc",
        width: 150,
      },
    ],
    // rows,
    rows: rows.map(row => ({
      ...row,
      fullName: row.displayFullName, // Use displayFullName for rendering
    })),
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="OnlineKorp"
            title="Users"
            breadcrumbItem="Users Services"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    {loading ? (
                      <div
                        style={{
                          textAlign: "center",
                          padding: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ThreeDots
                          visible={true}
                          height="80"
                          width="80"
                          color="#6f42c1"
                          radius="9"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      </div>
                    ) : (
                      <MDBDataTable responsive striped bordered data={data} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Users
